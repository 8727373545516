import * as Loaders from "react-spinners";

const componentsObject = { ...Loaders };

type LoaderType = keyof typeof componentsObject;

interface Props {
  type?: LoaderType;
  color?: string;
  size?: number;
}

const Spinner: React.FC<Props> = ({ type = "BarLoader", ...props }) => {
  const Component = componentsObject[type];

  return Component && <Component {...props} />;
};

export default Spinner;
