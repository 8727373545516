import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ApplicationState {
  moveDownPopups: boolean;
  onlineMode: boolean;
  isStepFreeRoute: boolean;
}

const initialState: ApplicationState = {
  moveDownPopups: false,
  onlineMode: false,
  isStepFreeRoute: false,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setMoveDownPopups: (state, action: PayloadAction<boolean>) => {
      state.moveDownPopups = action.payload;
    },
    setOnlineMode: (state, action: PayloadAction<boolean>) => {
      state.onlineMode = action.payload;
    },
    setIsStepFreeRoute: (state, action: PayloadAction<boolean>) => {
      state.isStepFreeRoute = action.payload;
    },
  },
});

export const { setMoveDownPopups, setOnlineMode, setIsStepFreeRoute } =
  applicationSlice.actions;

export default applicationSlice.reducer;
