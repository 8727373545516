import { Floors } from "../redux/services/config";

export enum FloorChange {
  UP = "up",
  DOWN = "down",
  NONE = "none",
}

interface FloorDifference {
  amount: number;
  direction: FloorChange;
}

export function getFloorDifference(
  id: number,
  defaultFloorId: number | null,
  allFloors: Floors,
): FloorDifference {
  if (!defaultFloorId) {
    return {
      amount: 0,
      direction: FloorChange.NONE,
    };
  }

  const floors = Object.values(allFloors);
  const [defaultFloorPos, featureFloorPos] = [defaultFloorId, id].map(
    (floorId) => floors.findIndex((floor) => floor.id === floorId),
  );

  const difference = featureFloorPos - defaultFloorPos;
  let direction = FloorChange.NONE;

  if (difference > 0) direction = FloorChange.UP;
  else if (difference < 0) direction = FloorChange.DOWN;

  return {
    amount: Math.abs(difference),
    direction: direction,
  };
}

export function getFloorDifferenceString(
  floorDifference: FloorDifference,
): string {
  return floorDifference.amount === 0
    ? "On this level"
    : `${floorDifference.amount} ${floorDifference.amount === 1 ? `level` : `levels`} ${floorDifference.direction}`;
}
