import { LMFeature } from "@livingmap/core-mapping";

import { Feature } from "../redux/services/config";
import { GeoJSONFeature } from "mapbox-gl";

interface EnhancedFeature extends Feature {
  state: {};
  layer: {
    id: string;
    type: string;
    source: string; // Add required properties as per ClipLayerSpecification or other required types
    // Include any additional properties required by the layer specifications
  };
}

export const createLMFeatures = (features: Feature[]) => {
  return features.map((feature) => {
    const enhancedFeature = {
      ...feature,
      state: {},
      layer: { ...feature.layer, source: "requiredSource" },
    } as EnhancedFeature;

    // If further adjustments or validations are needed, ensure they are done before instantiation
    return new LMFeature(enhancedFeature as GeoJSONFeature);
  });
};
