export enum MessageType {
  PING = "PING",
  SEND_DIAGNOSTICS = "SEND_DIAGNOSTICS",
  RELOAD_WEBVIEW = "RELOAD_WEBVIEW",
  OVERLAY_KIOSK_ID_SHOW = "OVERLAY_KIOSK_ID_SHOW",
  OVERLAY_KIOSK_ID_HIDE = "OVERLAY_KIOSK_ID_HIDE",
  OVERLAY_DIAGNOSTICS_SHOW = "OVERLAY_DIAGNOSTICS_SHOW",
  OVERLAY_DIAGNOSTICS_HIDE = "OVERLAY_DIAGNOSTICS_HIDE",
}

export enum MessageAcknowledgementType {
  PONG = "pong",
  OK = "ok",
  ERROR = "error",
  RESPONSE = "response",
}
