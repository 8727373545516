import * as turf from "@turf/turf";
import { Feature } from "geojson";

export type FeatureWithDistance = Feature & { distance?: number };

export const sortFeaturesByDistance = (
  startPoint: [number, number],
  features: Feature[],
): FeatureWithDistance[] => {
  const featuresWithDistance: FeatureWithDistance[] = features.map(
    (feature) => {
      if (!("coordinates" in feature.geometry)) return feature;

      const distance = turf.distance(
        startPoint,
        feature.geometry.coordinates as [number, number],
        { units: "kilometres" },
      );

      return { ...feature, distance };
    },
  );

  return featuresWithDistance.sort((a, b) => {
    if (a.distance && b.distance) return a.distance - b.distance;
    else return 0;
  });
};
