import {
  RouteQueryResponse,
  RouteMilestoneFeature,
} from "../redux/services/config";

export const generateFullRouteMilestones = (
  sequenceOrder: number[],
  segments: RouteQueryResponse["segments"],
) => {
  let features: RouteMilestoneFeature[] = [];

  sequenceOrder.forEach((segmentNum) => {
    const segment = segments[segmentNum];
    features = features.concat(segment.routeMilestones?.features || []);
  });

  return features;
};
