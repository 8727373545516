import { SessionDataRequestBody } from "../redux/services/config";

const FAILED_SESSION_REQUESTS_KEY = "failed_session_requests";

export const getFailedSessionRequests = () =>
  localStorage.getItem(FAILED_SESSION_REQUESTS_KEY)
    ? JSON.parse(localStorage.getItem(FAILED_SESSION_REQUESTS_KEY) as string)
    : [];

export const setFailedSessionRequests = (sessions: SessionDataRequestBody[]) =>
  localStorage.setItem(FAILED_SESSION_REQUESTS_KEY, JSON.stringify(sessions));
