import classNames from "classnames";
import { useContext } from "react";
import DOMPurify from "dompurify";

import { MapContext } from "../../../Map/Map";

import SearchResultImage from "../SearchResultImage/SearchResultImage";

import styles from "./SearchResultGroup.module.scss";
import { Icon } from "@livingmap/core-ui-v2";

export interface SearchResultProps {
  dataQA: string;
  className?: string;
  onClick: () => void;
  showImage: boolean;
  titleSearch: boolean;
  title: string;
  imageUrl: string | null;
}

export default function SearchResultGroup({
  dataQA,
  className,
  onClick,
  showImage,
  titleSearch,
  title,
  imageUrl,
}: SearchResultProps) {
  const { controlTheme, controlSize } = useContext(MapContext);

  return (
    <li
      data-qa={dataQA}
      className={classNames(
        styles.container,
        styles[controlSize],
        styles[controlTheme],
        className,
      )}
      onClick={onClick}
    >
      {showImage && <SearchResultImage imageUrl={imageUrl} />}
      <div className={styles.content}>
        <div className={styles.info}>
          <p
            className={classNames(styles.name, {
              [styles.titleSearch]: titleSearch,
            })}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
          />
          <p className={styles.description}>See all locations</p>
        </div>
        <div className={styles.iconContainer}>
          <Icon
            type="RightShortArrowIcon"
            dataQA="multiple-results-arrow-icon"
            className={styles.icon}
          />
        </div>
      </div>
    </li>
  );
}
