import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { differenceInSeconds } from "date-fns";

import {
  SessionDataRequestBody,
  useGetSessionData30DaysQuery,
  usePostSessionDataMutation,
} from "../redux/services/config";
import { setOnlineMode } from "../redux/slices/applicationSlice";

import { getFailedSessionRequests, setFailedSessionRequests } from "../utils";

export const useOnlineMode = (sendOfflineEvent: () => void) => {
  const dispatch = useDispatch();

  const screenId = new URLSearchParams(window.location.search).get("screen_id");

  if (!screenId) {
    window.displayErrorScreen("screen_id must be supplied to load app");
    throw new Error("screen_id must be supplied to load app");
  }

  const { fulfilledTimeStamp, startedTimeStamp, refetch, isFetching, isError } =
    useGetSessionData30DaysQuery(screenId);

  const [postSessionData] = usePostSessionDataMutation();

  const resendFailedSessionRequests = useCallback(() => {
    const pastFailedRequests = getFailedSessionRequests();
    const failedRequests: SessionDataRequestBody[] = [];

    if (pastFailedRequests.length) {
      pastFailedRequests.forEach((session: SessionDataRequestBody) => {
        postSessionData(session)
          .unwrap()
          .catch((error) => {
            if (error.status !== 400) failedRequests.push(session);
          });
      });

      setFailedSessionRequests(failedRequests);
    }
  }, [postSessionData]);

  useEffect(() => {
    if (isError) {
      dispatch(setOnlineMode(false));
      sendOfflineEvent();
      return;
    }

    if (fulfilledTimeStamp && startedTimeStamp && !isFetching) {
      const waitTime = differenceInSeconds(
        new Date(fulfilledTimeStamp),
        new Date(startedTimeStamp)
      );

      if (waitTime < 10) {
        dispatch(setOnlineMode(true));
        resendFailedSessionRequests();
      } else {
        dispatch(setOnlineMode(false));
        sendOfflineEvent();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    fulfilledTimeStamp,
    isError,
    isFetching,
    startedTimeStamp,
    resendFailedSessionRequests,
  ]);

  return { checkOnlineMode: refetch };
};
