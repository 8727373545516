import { iconsConfig } from "../icons/icons";

export const loadIcons = (map: mapboxgl.Map) => {
  iconsConfig.forEach(({ name, path, width, height }) => {
    fetch(path)
      .then((response) => response.text())
      .then((svgText) => {
        const svgBlob = new Blob([svgText], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const image = new Image();
        image.onload = () => {
          const scale = window.devicePixelRatio || 1;
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          // Set canvas dimensions to the desired size multiplied by the scale factor
          canvas.width = width * scale;
          canvas.height = height * scale;

          // Scale the context to account for high-DPI screens
          context?.scale(scale, scale);

          // Draw the SVG onto the canvas at the specified size
          // Note: The drawImage parameters are (sourceImage, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
          // To ensure the correct dimensions, we need to draw the image at its native dimensions first.
          context?.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            width,
            height,
          );

          // Convert the canvas to an ImageBitmap
          canvas.toBlob((blob) => {
            if (!blob) return;
            createImageBitmap(blob).then((bitmap) => {
              if (map) {
                map.addImage(name, bitmap);
                URL.revokeObjectURL(url); // Clean up the object URL
              }
            });
          });
        };
        image.src = url;
      })
      .catch((error) => {
        console.error("Error loading SVG:", error);
      });
  });
};
