import styles from "./IFrame.module.scss";
import classNames from "classnames";

interface Props {
  dataQA: string;
  className?: string;
  height: number;
  backgroundColor: string;
  position: "top" | "bottom";
  url: string;
}

export default function IFrame({
  dataQA,
  className,
  height,
  backgroundColor,
  position,
  url,
}: Props) {
  return (
    <iframe
      data-qa={dataQA}
      src={`${url}?autoplay=1&muted=1&loop=1&controls=0`}
      title={url}
      className={classNames(styles.container, className)}
      style={{
        height,
        backgroundColor,
        order: position === "bottom" ? "1" : "0",
      }}
      allow="autoplay"
    />
  );
}
