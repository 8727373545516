import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
  Utils,
} from "@livingmap/core-mapping";
import { FeatureCollection, Geometry } from "geojson";
import { SymbolLayout } from "mapbox-gl";

export const USER_LOCATION_LAYER_ID = "user-location-layer";
export const USER_LOCATION_LABEL_LAYER_ID = "user-location-label-layer";

const {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} = Utils;

const USER_LOCATION_SOURCE_ID = `${USER_LOCATION_LAYER_ID}-source`;
const USER_LOCATION_LABEL_SOURCE_ID = `${USER_LOCATION_LABEL_LAYER_ID}-source`;

export const EMPTY_DATA_SOURCE: FeatureCollection<Geometry> =
  createGeoJSONFeatureCollection([]);

interface Marker {
  bearing: number;
  latitude: number;
  longitude: number;
  floor: string;
  floorID: number;
}

interface UserLocationColour {
  r: number;
  g: number;
  b: number;
}

export default class PositionPlugin extends LivingMapPlugin {
  protected layerDelegate: LayerDelegate;
  private userLocationColour: UserLocationColour;
  private borderColour: string;

  constructor(id: string, LMMap: LivingMap) {
    super(id, LMMap);
    this.layerDelegate = this.LMMap.getLayerDelegate();
    this.userLocationColour = {
      r: 75,
      g: 130,
      b: 255,
    };
    this.borderColour = "#fff";
  }

  public activate(): void {
    this.createUserLocationLayer();
    this.createUserLocationLabelLayer();
  }

  public deactivate = () => {
    this.removeUserLocationLayer();
    this.removeUserLocationLabelLayer();
  };

  public setMarker(location: Marker): void {
    this.updateLocationSource(location);
  }

  private updateLocationSource(location: Marker): void {
    const userLocationGeometry = createGeoJSONGeometryPoint([
      location.longitude,
      location.latitude,
    ]);
    const userLocationFeature = createGeoJSONFeature(
      {
        heading: location.bearing || 0,
        floor_id: undefined, // set to undefined so the location dot appears on all floor levels
      },
      userLocationGeometry,
    );

    const userLocationFeatureCollection = createGeoJSONFeatureCollection([
      userLocationFeature,
    ]);

    this.updateLocationData(userLocationFeatureCollection);
    this.updateLocationLabelData(userLocationFeatureCollection);
  }

  private updateLocationData(newData: FeatureCollection<Geometry>): void {
    const sourceProxy = this.layerDelegate.getSourceProxy(
      USER_LOCATION_SOURCE_ID,
    );
    if (sourceProxy) {
      sourceProxy.setData(newData);
    }
  }

  private updateLocationLabelData(newData: FeatureCollection<Geometry>): void {
    const sourceProxy = this.layerDelegate.getSourceProxy(
      USER_LOCATION_LABEL_SOURCE_ID,
    );
    if (sourceProxy) {
      sourceProxy.setData(newData);
    }
  }

  public layerNotDefined = (layerId: string) => {
    const layer = this.LMMap.getLayerDelegate().getLayer(layerId);
    return !layer;
  };

  /**
   *
   * @param hex a hex colour for the user location dot
   * @param borderColour a border colour for the dot
   * @param displayPulse whether to display a pulsing animation
   */
  public updateUserLocationStyle = (
    hex: string,
    borderColour: string,
    displayPulse: boolean,
  ) => {
    this.borderColour = borderColour;

    this.hexToRGB(hex);

    this.LMMap.getMapboxMap().removeImage("location-dot");
    this.LMMap.getMapboxMap().addImage(
      "location-dot",
      this.createLocationDot(displayPulse),
      {
        pixelRatio: 1.33,
      },
    );

    this.LMMap.getMapboxMap().removeImage("location-label");
    this.LMMap.getMapboxMap().addImage(
      "location-label",
      this.createLocationLabel(),
      {
        pixelRatio: 1.33,
      },
    );
  };

  public createUserLocationLayer(): void {
    const userLocationLayerDoesNotExist = this.layerNotDefined(
      USER_LOCATION_LAYER_ID,
    );
    if (userLocationLayerDoesNotExist) {
      this.layerDelegate.addSource(USER_LOCATION_SOURCE_ID, {
        type: "geojson",
        data: EMPTY_DATA_SOURCE,
      });
    }

    const doesLayerNotExist = this.layerNotDefined(USER_LOCATION_LAYER_ID);
    if (doesLayerNotExist) {
      this.LMMap.getMapboxMap().addImage(
        "location-dot",
        this.createLocationDot(true),
        {
          pixelRatio: 1.33,
        },
      );

      const layerLayout: SymbolLayout = {
        "icon-image": "location-dot",
        "icon-offset": [0, 0],
        "icon-allow-overlap": true,
        "icon-rotate": ["get", "heading"],
        "icon-rotation-alignment": "map",
      };

      this.layerDelegate.addLayer({
        id: USER_LOCATION_LAYER_ID,
        type: "symbol",
        source: USER_LOCATION_SOURCE_ID,
        layout: layerLayout,
      });
    }
  }

  public createUserLocationLabelLayer(): void {
    const userLocationLabelLayerDoesNotExist = this.layerNotDefined(
      USER_LOCATION_LABEL_LAYER_ID,
    );
    if (userLocationLabelLayerDoesNotExist) {
      this.layerDelegate.addSource(USER_LOCATION_LABEL_SOURCE_ID, {
        type: "geojson",
        data: EMPTY_DATA_SOURCE,
      });
    }

    const doesLayerNotExist = this.layerNotDefined(
      USER_LOCATION_LABEL_LAYER_ID,
    );
    if (doesLayerNotExist) {
      this.LMMap.getMapboxMap().addImage(
        "location-label",
        this.createLocationLabel(),
        {
          pixelRatio: 1.33,
        },
      );

      const layerLayout: SymbolLayout = {
        "icon-image": "location-label",
        "icon-offset": [0, 0],
        "icon-allow-overlap": true,
      };

      this.layerDelegate.addLayer({
        id: USER_LOCATION_LABEL_LAYER_ID,
        type: "symbol",
        source: USER_LOCATION_LABEL_SOURCE_ID,
        layout: layerLayout,
      });
    }
  }

  private removeUserLocationLayer() {
    this.layerDelegate.removeLayer(USER_LOCATION_LAYER_ID);
    this.layerDelegate.removeSource(USER_LOCATION_SOURCE_ID);
  }

  private removeUserLocationLabelLayer() {
    this.layerDelegate.removeLayer(USER_LOCATION_LABEL_LAYER_ID);
    this.layerDelegate.removeSource(USER_LOCATION_LABEL_SOURCE_ID);
  }

  private createLocationDot(displayPulse: boolean) {
    const size = 80;

    /**
     * Modified version of code referenced from https://docs.mapbox.com/mapbox-gl-js/example/add-image-animated/
     */
    const locationDot: {
      width: number;
      height: number;
      data: Uint8Array | Uint8ClampedArray;
      context: CanvasRenderingContext2D | null;
      lmMap: LivingMap;
      userLocationColour: UserLocationColour;
      borderColour: string;
      onAdd: () => void;
      render: () => boolean;
    } = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),
      context: null,
      lmMap: this.LMMap,
      userLocationColour: this.userLocationColour,
      borderColour: this.borderColour,

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
      },

      // Call once before every frame where the icon will be used.
      render: function () {
        const duration = 1500; // Duration of the pulse animation
        const interval = 3000; // Interval between pulses (includes the animation and pause)

        // Calculate the current time within the current interval
        const t = (performance.now() % interval) / duration;

        // Only animate if within the animation duration, otherwise, hold the animation
        const animate = t < 1;

        const radius = (size / 2) * 0.3;
        let outerRadius;

        if (animate) {
          outerRadius = (size / 2) * 0.7 * t + radius;
        } else {
          outerRadius = radius; // Keep the outer radius equal to the inner radius when not animating
        }
        const context = this.context;

        const toRadians = (deg: number) => (deg * Math.PI) / 180;

        context!.clearRect(0, 0, this.width, this.height);

        if (displayPulse) {
          // Draw the outer circle.
          context!.beginPath();
          context!.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2,
          );
          context!.fillStyle = `rgba(${this.userLocationColour.r}, ${
            this.userLocationColour.g
          }, ${this.userLocationColour.b}, ${1 - t})`;
          context!.fill();
        }

        // Draw the directional cone
        context!.fillStyle = `rgba(${this.userLocationColour.r}, ${this.userLocationColour.g}, ${this.userLocationColour.b}, 0.15)`;
        context!.beginPath();
        context!.moveTo(this.width / 2, this.height / 2);
        context!.arc(
          this.width / 2,
          this.height / 2,
          size / 2,
          toRadians(240),
          toRadians(300),
        );
        context!.lineTo(this.width / 2, this.height / 2);
        context!.closePath();
        context!.fill();

        // Draw the inner circle.
        context!.beginPath();
        context!.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context!.fillStyle = `rgba(${this.userLocationColour.r}, ${this.userLocationColour.g}, ${this.userLocationColour.b}, 1)`;
        context!.strokeStyle = this.borderColour;
        context!.lineWidth = 4;
        context!.shadowOffsetX = 0;
        context!.shadowOffsetY = 0;
        context!.shadowBlur = 8;
        context!.shadowColor = "rgba(0, 0, 0, 0.3)";
        context!.fill();
        context!.stroke();

        // Update this image's data with data from the canvas.
        this.data = context!.getImageData(0, 0, this.width, this.height).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        this.lmMap.getMapboxMap().triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      },
    };

    return locationDot;
  }

  private createLocationLabel() {
    const size = 200;

    const locationLabel: {
      width: number;
      height: number;
      data: Uint8Array | Uint8ClampedArray;
      context: CanvasRenderingContext2D | null;
      lmMap: LivingMap;
      userLocationColour: UserLocationColour;
      borderColour: string;
      onAdd: () => void;
      render: () => boolean;
    } = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),
      context: null,
      lmMap: this.LMMap,
      userLocationColour: this.userLocationColour,
      borderColour: this.borderColour,

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
      },

      // Call once before every frame where the icon will be used.
      render: function () {
        const context = this.context;

        context!.clearRect(0, 0, this.width, this.height);

        context!.beginPath();
        context!.fillStyle = `rgba(${this.userLocationColour.r}, ${this.userLocationColour.g}, ${this.userLocationColour.b}, 1)`;
        context!.shadowOffsetX = 0;
        context!.shadowOffsetY = 0;
        context!.shadowBlur = 8;
        context!.shadowColor = "rgba(0, 0, 0, 0.3)";
        context!.fill();
        context!.stroke();

        // Draw the "You" label
        context!.beginPath();
        // First draw the label shape
        context!.moveTo(120, 100);
        context!.lineTo(140, 80);
        context!.lineTo(195, 80);
        context!.lineTo(195, 120);
        context!.lineTo(140, 120);
        context!.fill();
        // Then draw the text on top
        context!.fillStyle = "white";
        context!.font = "bold 16pt Gotham";
        context!.fillText("You", 145, 107);

        // Update this image's data with data from the canvas.
        this.data = context!.getImageData(0, 0, this.width, this.height).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        this.lmMap.getMapboxMap().triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      },
    };

    return locationLabel;
  }

  /**
   *
   * @param hex
   *
   * Modified code referenced from https://github.com/sindresorhus/hex-rgb/blob/main/index.js
   */
  private hexToRGB = (hex: string) => {
    const hexCharacters = "a-f\\d";
    const match3or4Hex = `#?[${hexCharacters}]{3}[${hexCharacters}]?`;
    const match6or8Hex = `#?[${hexCharacters}]{6}([${hexCharacters}]{2})?`;
    const nonHexChars = new RegExp(`[^#${hexCharacters}]`, "gi");
    const validHexSize = new RegExp(`^${match3or4Hex}$|^${match6or8Hex}$`, "i");

    if (
      typeof hex !== "string" ||
      nonHexChars.test(hex) ||
      !validHexSize.test(hex)
    ) {
      throw new TypeError("Expected a valid hex string");
    }

    hex = hex.replace(/^#/, "");

    if (hex.length === 8) {
      hex = hex.slice(0, 6);
    }

    if (hex.length === 4) {
      hex = hex.slice(0, 3);
    }

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    const number = Number.parseInt(hex, 16);
    const red = number >> 16;
    const green = (number >> 8) & 255;
    const blue = number & 255;

    this.userLocationColour = {
      r: red,
      g: green,
      b: blue,
    };
  };
}
