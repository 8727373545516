import { configApi } from "../redux/services/config";
import { store } from "../store";
import { UISize, UITheme } from "./../components/types";

export interface ControlTheme {
  size: UISize;
  mode: UITheme;
  margin_bottom: number;
}

export const getFloorById = (id: number) => {
  const screenId = new URLSearchParams(window.location.search).get("screen_id");
  const storeSelector = configApi.endpoints.getConfig.select(screenId!);
  const floors = storeSelector(store.getState()).data?.floors;

  if (!floors) {
    return null;
  }

  const found = Object.keys(floors).find((floor) => floors[floor].id === id);

  if (!found) return null;

  return floors[found];
};

export const getControlTheme = () => {
  const screenId = new URLSearchParams(window.location.search).get("screen_id");
  const storeSelector = configApi.endpoints.getConfig.select(screenId!);
  const theme: ControlTheme = storeSelector(store.getState()).data?.components
    ?.map_primary?.controls?.theme;

  if (!theme) {
    return null;
  }

  return theme;
};
