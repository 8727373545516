/**
 * Utility to throttle a given callback
 *
 * @param callback the callback to throttle
 * @param delay the delay in milliseconds to throttle the callback by
 * @returns the throttled callback
 */
export const throttle = (callback: (...args: any[]) => void, delay: number) => {
  let shouldWait = false;

  return (...args: any[]) => {
    if (shouldWait) return;

    callback(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
};
