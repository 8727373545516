import { RouteQueryResponse } from "../redux/services/config";

export const generateFullRoute = (
  sequenceOrder: number[],
  segments: RouteQueryResponse["segments"],
) => {
  let features: RouteQueryResponse["segments"][number]["routeGeoJson"] = [];

  sequenceOrder.forEach((segmentNum) => {
    const segment = segments[segmentNum];
    features = features.concat(segment.routeGeoJson);
  });

  return features;
};
