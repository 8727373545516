import { useState, useEffect, useCallback } from "react";

import {
  useLazyGetNextMessageQuery,
  usePostJsonMessageMutation,
  usePostPlainTextMessageMutation,
} from "../redux/services/messagingProtocol";

import {
  MessageAcknowledgementType,
  MessageType,
} from "../types/messagingProtocol";

import getRandomInt from "../utils/getRandomInt";

export const useMessagingProtocol = (
  screen_id: string,
  actionMessage: (messageType: MessageType) => void,
  getScreenDiagnostics: () => Record<string, any>
) => {
  const [messageCheckScheduled, setMessageCheckScheduled] = useState(false);

  const [getNextMessage, nextMessageResult] = useLazyGetNextMessageQuery();

  const [postPlainTextMessage] = usePostPlainTextMessageMutation();

  const [postJsonMessage] = usePostJsonMessageMutation();

  const getMessage = useCallback(
    (messageType: MessageType): string | Record<string, any> => {
      switch (messageType) {
        case MessageType.SEND_DIAGNOSTICS:
          return getScreenDiagnostics();
        case MessageType.PING:
          return "Here is my pong";
        case MessageType.RELOAD_WEBVIEW:
          return "Webview is about to be reloaded";
        case MessageType.OVERLAY_KIOSK_ID_SHOW:
          return "Kiosk ID Overlay is to be shown";
        case MessageType.OVERLAY_KIOSK_ID_HIDE:
          return "Kiosk ID Overlay is to be hidden";
        case MessageType.OVERLAY_DIAGNOSTICS_SHOW:
          return "Diagnostics Overlay is to be shown";
        case MessageType.OVERLAY_DIAGNOSTICS_HIDE:
          return "Diagnostics Overlay is to be hidden";
        default:
          return "";
      }
    },
    [getScreenDiagnostics]
  );

  const handlePostResponseMessage = useCallback(
    (messageResponse: { messageType: MessageType; uuid: string }) => {
      const { messageType, uuid } = messageResponse;
      const props = { uuid, id: screen_id };

      let type =
        messageType === MessageType.PING
          ? MessageAcknowledgementType.PONG
          : MessageAcknowledgementType.OK;

      let message = getMessage(messageType);

      if (!message) {
        type = MessageAcknowledgementType.ERROR;
        message = `${uuid} Failed to action due to unrecognized message type ${messageType}`;
      }

      if (typeof message === "object") {
        postJsonMessage({ ...props, message });
      } else {
        postPlainTextMessage({
          ...props,
          message,
          type,
        });
      }
    },
    [getMessage, postJsonMessage, postPlainTextMessage, screen_id]
  );

  useEffect(() => {
    if (
      nextMessageResult.data &&
      !nextMessageResult.isLoading &&
      nextMessageResult.isSuccess
    ) {
      // to match a message request in the form of <string> <uuid> <string>
      const regex =
        /^(\w+)\s+([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:\s+(.*))?$/;
      const [, messageType, uuid] = nextMessageResult.data.match(regex) || [];

      if (messageType && uuid) {
        handlePostResponseMessage({
          messageType: messageType as MessageType,
          uuid,
        });
        actionMessage(messageType as MessageType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nextMessageResult.data,
    nextMessageResult.isLoading,
    nextMessageResult.isSuccess,
  ]);

  useEffect(() => {
    if (!messageCheckScheduled) {
      const interval = getRandomInt(30, 60);

      setTimeout(() => {
        getNextMessage(screen_id);
        setMessageCheckScheduled(false);
      }, interval * 1000);

      setMessageCheckScheduled(true);
    }
  }, [getNextMessage, messageCheckScheduled, screen_id]);
};
