import classNames from "classnames";
import QRCode from "react-qr-code";
import { Icon } from "@livingmap/core-ui-v2";

import { Theme } from "../../../../redux/services/config";

import { OperatingHours, scaleValue } from "../../../../utils";
import { FloorChange } from "../../../../utils/getFloorDifference";

import FeatureInformation from "../../../FeatureInformation/FeatureInformation";

import styles from "./AssetInformation.module.scss";

interface Props {
  dataQA: string;
  theme: Theme;
  name: string;
  operatingHours: OperatingHours;
  qrCodeLink: string;
  category?: string;
  building?: string;
  floorName?: string;
  className?: string;
  isTemporarilyClosed: boolean;
  onClose?: () => void;
  imageSrc?: string;
  floorChange: { direction: FloorChange; amount: number };
  kilometres?: number;
  showNearestLabel?: boolean;
  showQRCode?: boolean;
}

const AssetInformation: React.FC<Props> = ({
  dataQA,
  theme,
  name,
  operatingHours,
  qrCodeLink,
  category,
  building,
  floorName,
  className,
  isTemporarilyClosed,
  onClose,
  imageSrc,
  floorChange,
  kilometres,
  showNearestLabel,
  showQRCode,
}) => {
  return (
    <div
      data-qa={dataQA}
      className={classNames(
        styles.container,
        styles[theme.mode || "light"],
        styles[theme.size || "small"],
        className,
      )}
    >
      <div
        className={classNames(styles.qrCodeContainer, {
          [styles.hide]: !showQRCode,
        })}
      >
        <div className={styles.qrCodeContent}>
          <Icon
            dataQA="qr-code-icon"
            type="ShareDirectionsIcon"
            height={scaleValue(14, theme.size || "medium")}
          />
          <p>Scan the code to view on your phone</p>
          <div className={styles.qrCode}>
            <QRCode
              value={qrCodeLink}
              size={scaleValue(92, theme.size || "medium")}
            />
          </div>
        </div>
      </div>
      <div className={styles.infoContainer}>
        {imageSrc && (
          <img
            data-qa="asset-background"
            src={imageSrc}
            alt="asset-background"
            className={styles.image}
          />
        )}
        <FeatureInformation
          dataQA="feature-information"
          {...{
            name,
            category,
            floorName,
            building,
            operatingHours,
            isTemporarilyClosed,
            floorChange,
            kilometres,
            imageSrc,
            theme,
          }}
        />
        {onClose && (
          <button onClick={onClose} className={styles.closeBtn}>
            <Icon
              dataQA="poi-close-icon"
              type="CloseIcon"
              width={scaleValue(14, theme.size || "medium")}
              height={scaleValue(14, theme.size || "medium")}
            />
          </button>
        )}
        {!onClose && showNearestLabel && (
          <div className={styles.label}>Nearest</div>
        )}
      </div>
    </div>
  );
};

export default AssetInformation;
