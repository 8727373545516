import { useCallback } from "react";

import {
  RouteMilestoneFeature,
  RouteQueryResponse,
} from "../redux/services/config";

import { generateFullRoute } from "./../utils/generateFullRoute";
import { generateFullRouteMilestones } from "./../utils/generateFullRouteMilestones";

import RoutingPlugin from "../components/Map/plugins/routing-control";

interface Props {
  segments?: RouteQueryResponse["segments"];
  sequenceOrder?: number[];
  routingControl: RoutingPlugin | null;
}

enum FloorChangeMethod {
  STEPS = "steps",
  STAIRS = "stairs",
  ESCALATOR = "escalator",
  LIFT = "lift",
}

export const useDisplayRoute = ({
  segments,
  sequenceOrder,
  routingControl,
}: Props) => {
  const routeHasSteps = useCallback((milestones: RouteMilestoneFeature[]) => {
    let hasSteps = false;

    for (const i in milestones) {
      const method = milestones[i].properties.method;
      if (
        method === FloorChangeMethod.STEPS ||
        method === FloorChangeMethod.STAIRS ||
        method === FloorChangeMethod.ESCALATOR
      ) {
        hasSteps = true;
        break;
      }
    }

    return hasSteps;
  }, []);

  const renderRoute = useCallback(() => {
    if (segments && sequenceOrder) {
      const features = generateFullRoute(sequenceOrder, segments);
      const milestones = generateFullRouteMilestones(sequenceOrder, segments);
      routingControl?.renderRoute(features, milestones);

      return { routeHasSteps: routeHasSteps(milestones) };
    }

    return { routeHasSteps: false };
  }, [routeHasSteps, routingControl, segments, sequenceOrder]);

  return { renderRoute };
};
