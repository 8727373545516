export const scaleValue = (
  value: number,
  size: "small" | "medium" | "large",
) => {
  let scaledValue = value;

  switch (size) {
    case "small":
      scaledValue *= 0.8;
      break;
    case "large":
      scaledValue *= 1.2;
      break;
    default:
  }

  if (window.innerWidth >= 2160) {
    scaledValue *= 2;
  }

  return scaledValue;
};
