import { useState, useEffect } from "react";

export const useLanguageSwitcher = (
  languages: string[],
  languageInterval: number
) => {
  const [currentLanguage, setCurrentLanguage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (languages.length - 1 === currentLanguage) {
        setCurrentLanguage(0);
      } else {
        setCurrentLanguage(currentLanguage + 1);
      }
    }, languageInterval);
  }, [currentLanguage, languageInterval, languages]);

  return currentLanguage;
};
