import poiMarkerActive from "./poi_marker_active.svg";
import poiMarkerFloor from "./poi_marker_floor.svg";
import poiMarkerInactive from "./poi_marker_inactive.svg";

const Icons = { poiMarkerActive, poiMarkerFloor, poiMarkerInactive };
type IconType = keyof typeof Icons;

const getIcon = (name: string) => Icons[name as IconType];

export const iconsConfig = [
  {
    name: "feature_marker_active",
    path: getIcon("poiMarkerActive"),
    width: 26,
    height: 40,
  },
  {
    name: "feature_marker_floor",
    path: getIcon("poiMarkerFloor"),
    width: 22,
    height: 32,
  },
  {
    name: "feature_marker_inactive",
    path: getIcon("poiMarkerInactive"),
    width: 22,
    height: 32,
  },
];
