import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { SessionDataResponse } from "./config";

export const messagingProtocolApi = createApi({
  reducerPath: "messagingProtocolApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getNextMessage: builder.query<string, string>({
      query: (id) => ({
        url: `/${id}/messages`,
        responseHandler: (response) => response.text(),
      }),
    }),
    postPlainTextMessage: builder.mutation<SessionDataResponse, any>({
      query: ({ id, message, type, uuid }) => ({
        url: `/${id}/messages/${type}/${uuid}`,
        method: "POST",
        body: `${type.toUpperCase()} ${message}`,
        jsonContentTypes: ["text/plain"],
      }),
    }),
    postJsonMessage: builder.mutation<SessionDataResponse, Record<string, any>>(
      {
        query: ({ id, message, uuid }) => ({
          url: `/${id}/messages/response/${uuid}`,
          method: "POST",
          body: { type: "RESPONSE", message },
        }),
      }
    ),
  }),
});

export const {
  useLazyGetNextMessageQuery,
  usePostPlainTextMessageMutation,
  usePostJsonMessageMutation,
} = messagingProtocolApi;
