import { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { Feature, Floors } from "../../../../redux/services/config";

import { MapContext } from "../../Map";

import { getFloorDifference } from "../../../../utils/getFloorDifference";
import { getFormattedFloors } from "../../../../utils/getFormattedFloors";

import styles from "./SearchResults.module.scss";
import SearchResult from "../../../SearchResult/SearchResult";
import SearchResultGroup from "../../../SearchResult/components/SearchResultGroup/SearchResultGroup";

function normalize(string: string) {
  if (!string) return string;

  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // replace diacritics with their 'base' letter (i.e. é -> e)
    .replace(/\./g, "") // remove periods
    .replace(/ /g, ""); // remove spaces
}

function regexify(searchTerm: string): RegExp {
  const normalizedSearchTerm = normalize(searchTerm);

  const result = normalizedSearchTerm
    .replace(/.{1}/g, "($&|[^a-zA-Z0-9])") // any character can be substituted for non-alphanumeric characters
    .replace(/.{16}/g, "$&[^a-zA-Z0-9]?"); // allow any non alphanumeric character after each original character

  return new RegExp(result, "gi");
}

function wrapSearchTerm(searchTerm: string, name: string): string {
  const regex = regexify(searchTerm);
  return name.replace(regex, "<span>$&</span>").replace(/ /g, "&nbsp;"); // ensures whitespace is still rendered inside of the "inline-block" span. Span needs to be set to "inline-block" so the highlighted background stretches the full line-height of the p tag
}

interface SearchResultStore {
  [key: string]: Feature[];
}

const MIN_CHARS = 2;

export interface SearchResultsProps {
  dataQA: string;
  features: Feature[];
  searchTerm: string;
  className?: string;
  enableImages: boolean;
  onSearchInputActive: (enabled: boolean) => void;
  onResultClick: (features: Feature[]) => void;
  onScroll?: () => void;
  floors: Floors;
  defaultFloorId: number;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  dataQA,
  features,
  searchTerm,
  onSearchInputActive,
  onResultClick,
  onScroll,
  className,
  enableImages,
  floors,
  defaultFloorId,
}) => {
  const { controlTheme, controlSize } = useContext(MapContext);

  // Store the search results in an object where the key is the feature name which holds an array of features associated with the same name, so we can return all of them on result click
  const [resultStore, setResultStore] = useState<SearchResultStore>({});

  const formattedFloors = getFormattedFloors(floors);

  useEffect(() => {
    setResultStore({});

    if (searchTerm.length >= MIN_CHARS) {
      if (/ {2}/.test(searchTerm)) {
        // if there are two spaces in a row, reset the result store
        setResultStore({});
        return;
      }

      for (const feature of features) {
        if (
          !feature.properties.popup_header ||
          !regexify(searchTerm).test(
            normalize(feature.properties.popup_header.toLowerCase()),
          )
        )
          continue;

        setResultStore((prevResultStore) => ({
          ...prevResultStore,
          [feature.properties.popup_header]: prevResultStore[
            feature.properties.popup_header
          ]
            ? [...prevResultStore[feature.properties.popup_header], feature]
            : [feature],
        }));
      }
    }
  }, [features, onSearchInputActive, searchTerm]);

  useEffect(() => {
    if (searchTerm.length >= MIN_CHARS) {
      onSearchInputActive(Object.keys(resultStore).length > 0);
    } else {
      onSearchInputActive(true);
    }
  }, [onSearchInputActive, resultStore, searchTerm.length]);

  if (Object.keys(resultStore).length === 0 && searchTerm.length < 2)
    return null;

  return (
    <div
      data-qa={dataQA}
      className={classNames(
        styles.container,
        styles[controlTheme],
        styles[controlSize],
        className,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {Object.keys(resultStore).length ? (
        <div className={styles.scrollContainer} onScroll={onScroll}>
          <ul className={styles.listContainer}>
            {Object.keys(resultStore).map((resultKey) => {
              if (resultStore[resultKey].length > 1)
                return (
                  <SearchResultGroup
                    className={styles.searchResult}
                    dataQA="search-results-group"
                    showImage={enableImages}
                    key={resultStore[resultKey][0].id}
                    onClick={() => onResultClick(resultStore[resultKey])}
                    titleSearch={true} // TODO: this will depend on whether it is offline/online mode in the future
                    title={wrapSearchTerm(
                      searchTerm,
                      resultStore[resultKey][0].properties.popup_header,
                    )}
                    imageUrl={
                      resultStore[resultKey][0].properties.popup_image_url
                    }
                  />
                );
              const feature = resultStore[resultKey][0];

              const { amount, direction } = getFloorDifference(
                feature.properties.floor_id,
                defaultFloorId,
                floors,
              );

              return (
                <SearchResult
                  dataQA="search-result"
                  category={feature.properties.popup_subheader}
                  className={styles.searchResult}
                  floorName={
                    formattedFloors.find(
                      ({ id }) => id === feature.properties.floor_id,
                    )?.name
                  }
                  imageUrl={feature.properties.popup_image_url}
                  key={feature.id}
                  onClick={() => onResultClick(resultStore[resultKey])}
                  openingTimes={feature.properties.opening_times}
                  showImage={enableImages}
                  title={wrapSearchTerm(
                    searchTerm,
                    feature.properties.popup_header,
                  )}
                  titleSearch={true} // TODO: this will depend on whether it is offline/online mode in the future
                  isTemporarilyClosed={feature.properties.is_temporarily_closed}
                  floorChange={{
                    direction,
                    amount,
                  }}
                />
              );
            })}
          </ul>
        </div>
      ) : null}
      {!Object.keys(resultStore).length && searchTerm.length >= MIN_CHARS && (
        <div className={styles.noResultsContainer}>
          <div className={styles.title}>No results</div>
          <div className={styles.paragraph}>
            Check your spelling or try another search.
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
