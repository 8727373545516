import { LngLatBounds } from "mapbox-gl";

import { Feature } from "../redux/services/config";

export const getBoundingBox = (features: Feature[]) => {
  const bounds = new LngLatBounds();

  for (const feature of features) {
    if (!("coordinates" in feature.geometry)) continue;

    bounds.extend(feature.geometry.coordinates as [number, number]);
  }

  return bounds;
};
