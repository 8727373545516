import { useState, useRef, useEffect, useCallback } from "react";

import {
  EventType,
  InteractionEventTypes,
  SessionDataRequestBody,
  usePostSessionDataMutation,
} from "../redux/services/config";

import { getFailedSessionRequests, setFailedSessionRequests } from "../utils";

export const useSession = (
  sessionTimeout: number,
  sessionTimeoutNotice: number,
  onSessionTimeout: () => void,
  screenId: string,
) => {
  const timeout =
    new URLSearchParams(window.location.search).get("timeout") !== "false";

  const [postSessionData] = usePostSessionDataMutation();

  const timeoutID = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeoutNoticeID = useRef<ReturnType<typeof setTimeout> | null>(null);
  const events = useRef<EventType[]>([]);

  const [isInactive, setIsInactive] = useState(false);
  const isSessionActive = useRef(false);
  const sessionStart = useRef<string | null>(null);

  // We need to store the onSessionTimeout function as a ref, otherwise the timeout creates a closure and only executes the initial function that was passed in, but the function contains a state variable that could change before the timeout executes.
  const handleSessionTimeout = useRef<typeof onSessionTimeout>();

  useEffect(() => {
    handleSessionTimeout.current = onSessionTimeout;
  }, [onSessionTimeout]);

  const initialiseTimeout = useCallback(() => {
    console.debug(`Initialising "End session" timeout in ${sessionTimeout}ms`);

    return setTimeout(() => {
      console.debug('Executing "End session" timeout');

      if (handleSessionTimeout.current) {
        handleSessionTimeout.current();
      }

      if (sessionStart.current && events.current.length > 0) {
        const data: SessionDataRequestBody = {
          id: screenId,
          session_start: sessionStart.current,
          session_end: new Date().toISOString(),
          component: "map_primary",
          events: events.current,
        };

        postSessionData(data)
          .unwrap()
          .catch((error) => {
            if (error.status !== 400) {
              const pastFailedRequests = getFailedSessionRequests();
              setFailedSessionRequests([...pastFailedRequests, data]);
            }
          });

        events.current = [];
        sessionStart.current = null;
      }

      setIsInactive(false);
      isSessionActive.current = false;
    }, sessionTimeout);
  }, [postSessionData, sessionTimeout, screenId]);

  const initialiseTimeoutNotice = useCallback(() => {
    console.debug(
      `Initialising "Are you still there?" notice timeout in ${sessionTimeoutNotice}ms`,
    );

    return setTimeout(() => {
      console.debug('Executing "Are you still there?" notice timeout');

      setIsInactive(true);
    }, sessionTimeoutNotice);
  }, [sessionTimeoutNotice]);

  const resetTimeouts = useCallback(() => {
    if (timeoutID.current) clearTimeout(timeoutID.current);
    if (timeoutNoticeID.current) clearTimeout(timeoutNoticeID.current);

    timeoutID.current = initialiseTimeout();
    timeoutNoticeID.current = initialiseTimeoutNotice();

    setIsInactive(false);
  }, [initialiseTimeout, initialiseTimeoutNotice]);

  const handleTouchEvent = (
    eventType: InteractionEventTypes,
    enableLogging: boolean,
    resetSessionTimeouts: boolean = true,
    extraData?: { [key: string]: any },
  ) => {
    if (!isSessionActive.current) {
      const sesstionStartTime = new Date().toISOString();
      isSessionActive.current = true;
      sessionStart.current = sesstionStartTime;
    }

    if (enableLogging) {
      events.current = [
        ...events.current,
        {
          timestamp: new Date().toISOString(),
          event_type: eventType,
          ...extraData,
        },
      ];
    }

    if (resetSessionTimeouts) {
      resetTimeouts();
    }
  };

  return timeout
    ? { handleTouchEvent, isInactive, resetTimeouts }
    : {
        handleTouchEvent: () => {},
        isInactive: false,
        resetTimeouts: () => {},
      };
};
