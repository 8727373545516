import { useCallback, useMemo } from "react";
import { format, differenceInSeconds } from "date-fns";
import { UAParser } from "ua-parser-js";

import { FullHdResolution } from "../components/types";

interface Props {
  timezone: string;
  displayResolutionY: number;
  displayResolutionX: number;
  language: string;
  kioskIdOverlay: boolean;
  diagnosticsOverlay: boolean;
  uptime: string | null;
}

export const useScreenDiagnostics = ({
  timezone,
  displayResolutionY,
  displayResolutionX,
  language,
  kioskIdOverlay,
  diagnosticsOverlay,
  uptime,
}: Props) => {
  const staticScreenDiagnostics = useMemo(() => {
    const parser = new UAParser(window.navigator.userAgent);
    const { browser, os, ua } = parser.getResult();

    return {
      timezone: timezone,
      screen_resolution: {
        height: displayResolutionY,
        width: displayResolutionX,
        scaling_percent:
          displayResolutionX === FullHdResolution.x ||
          displayResolutionX === FullHdResolution.y
            ? 100
            : 200,
      },
      host: window.location.host,
      operating_system: `${os.name} ${os.version}`,
      browser_or_webview: {
        name: browser.name,
        version: browser.version,
        user_agent: ua,
        language,
      },
      overlays: {
        kiosk_id: kioskIdOverlay ? "visible" : "hidden",
        diagnostics: diagnosticsOverlay ? "visible" : "hidden",
      },
    };
  }, [
    diagnosticsOverlay,
    displayResolutionX,
    displayResolutionY,
    kioskIdOverlay,
    language,
    timezone,
  ]);

  const getScreenDiagnostics = useCallback(() => {
    const formatStyle = "yyyy-MM-dd HH:mm:ss";
    const currentDate = new Date();

    const localDate = format(currentDate, formatStyle);
    const utcDate = format(new Date(currentDate.toISOString()), formatStyle);

    const uptimeInSeconds =
      uptime && differenceInSeconds(new Date(), new Date(uptime));

    return {
      timestamp_utc: utcDate,
      timestamp_local: localDate,
      uptime_seconds: uptimeInSeconds,
      ...staticScreenDiagnostics,
    };
  }, [staticScreenDiagnostics, uptime]);

  return { getScreenDiagnostics };
};
