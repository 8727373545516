type OpeningTimeInterval = {
  opening_time: string;
  closing_time: string;
};

export interface OperatingHours {
  monday: OpeningTimeInterval | null;
  tuesday: OpeningTimeInterval | null;
  wednesday: OpeningTimeInterval | null;
  thursday: OpeningTimeInterval | null;
  friday: OpeningTimeInterval | null;
  saturday: OpeningTimeInterval | null;
  sunday: OpeningTimeInterval | null;
}

// first regex pattern will look for all matches in given string
const OPENING_TIME_GLOBAL_REGEX = /[a-z]{3}: [0-9:]+ - [0-9:]+/g;

// second regex pattern takes match from first pattern and pulls the day, opening and closing times out as capture groups
const OPENING_TIME_REGEX = /^([a-z]{3}): ([0-9:]+) - ([0-9:]+)$/;

const NULL_TIMES: OperatingHours = {
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
};

type LongDay =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

const SHORT_TO_LONG_DAY_MAP = new Map<string, LongDay>([
  ["mon", "monday"],
  ["tue", "tuesday"],
  ["wed", "wednesday"],
  ["thu", "thursday"],
  ["fri", "friday"],
  ["sat", "saturday"],
  ["sun", "sunday"],
]);

export const parseOpeningTimeString = (
  openingTimeString: string | null
): OperatingHours => {
  const operatingHours = { ...NULL_TIMES };

  if (!openingTimeString) return operatingHours;

  const matches = openingTimeString.match(OPENING_TIME_GLOBAL_REGEX);
  if (!matches) return operatingHours;

  matches.forEach((match) => {
    const groupMatch = match.match(OPENING_TIME_REGEX);

    const [, day, opening_time, closing_time] = groupMatch!;

    const longDayName = SHORT_TO_LONG_DAY_MAP.get(day);

    if (!longDayName)
      throw Error(
        `Could not parse opening time string with bad 'day' identifier: ${day}`
      );

    operatingHours[longDayName] = { opening_time, closing_time };
  });

  return operatingHours;
};
