export const buildRouteShortlink = (
  featureId?: string | number,
  stepFree = false
) => {
  const screenId = new URLSearchParams(window.location.search).get("screen_id");
  const stepFreeQueryParam = `?step_free=${stepFree ? "enable" : "disable"}`;

  return process.env.REACT_APP_SHORTLINK_URL
    ? `${process.env.REACT_APP_SHORTLINK_URL}/r/${screenId}/${featureId}/${stepFreeQueryParam}`
    : `https://shortlink-api.dev1.livingmap.com/v1/r/${screenId}/${featureId}/${stepFreeQueryParam}`;
};
