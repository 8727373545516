import { useState } from "react";

import { Feature } from "../redux/services/config";

const preloadImage = (src: string) => {
  return new Promise((resolve) => {
    const img = new Image();

    // We want this to resolve whether it loads or errors out, as the resolved promises will define when to remove the splash screen. Any images that failed to load can always be refetched when it renders as a search result.
    img.onload = () => resolve(img);
    img.onerror = () => resolve(img);
    img.onabort = () => resolve(img);
    img.src = src;
  });
};

export const useImagePreloader = (
  features: Feature[] | undefined,
  enable: boolean
) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  if (!enable) {
    return { imagesPreloaded };
  }

  const handlePreload = async () => {
    if (!features?.length || imagesPreloaded) return;

    const featureImages = features.map((feature) => {
      if (!feature.properties.popup_image_url) return null;

      return preloadImage(feature.properties.popup_image_url);
    });

    await Promise.all(featureImages);

    setImagesPreloaded(true);
  };

  handlePreload();

  return { imagesPreloaded };
};
