import { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Icon } from "@livingmap/core-ui-v2";

import { MapContext } from "../../Map";

import TextKeyboard from "./components/TextKeyboard/TextKeyboard";

import styles from "./Keyboard.module.scss";

export interface KeyboardProps {
  dataQA: string;
  project: string;
  onChange: (inputValue: string) => void;
  onClose: () => void;
  enableInput: boolean;
  searchTerm?: string;
}

const Keyboard: React.FC<KeyboardProps> = ({
  dataQA,
  project,
  onChange,
  onClose,
  enableInput,
  searchTerm,
}) => {
  const { controlTheme, controlSize } = useContext(MapContext);

  const inputField = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [caretOffset, setCaretOffset] = useState(0);

  const projectTitle = project
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const inputCharacters = inputValue.split("");

    // Limit input to 1000 characters
    if (inputCharacters.length >= 1000) return;

    // Input has been disabled from external source
    if (!enableInput) return;

    // Insert input character at the caret's current position
    inputCharacters.splice(
      inputValue.length - caretOffset,
      0,
      e.currentTarget.name,
    );

    setInputValue(inputCharacters.join(""));
  };

  const handleBackspace = () => {
    const inputCharacters = inputValue.split("");

    // Only delete a character if there's a character to the left of the caret
    if (inputValue.length - caretOffset > 0) {
      inputCharacters.splice(inputValue.length - caretOffset - 1, 1);
      setInputValue(inputCharacters.join(""));
    }
  };

  const handleArrowClick = (direction: "left" | "right") => {
    if (direction === "left" && caretOffset < inputValue.length) {
      setCaretOffset(caretOffset + 1);
    }

    if (direction === "right" && caretOffset > 0) {
      setCaretOffset(caretOffset - 1);
    }
  };

  const handleEventPropagation = (
    e:
      | React.MouseEvent<HTMLInputElement, MouseEvent>
      | React.TouchEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFocus = useCallback(() => {
    if (!inputField.current || !inputValue) return;

    inputField.current.focus();
    inputField.current.setSelectionRange(
      inputValue.length - caretOffset,
      inputValue.length - caretOffset,
    );
    inputField.current.scrollLeft = inputField.current.scrollWidth;
  }, [caretOffset, inputValue]);

  useEffect(() => {
    // Shift the caret in the input if the offset updates
    inputField.current?.setSelectionRange(
      inputValue.length - caretOffset,
      inputValue.length - caretOffset,
    );

    handleFocus();
  }, [inputValue, caretOffset, handleFocus]);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue, onChange]);

  useEffect(() => {
    if (searchTerm) setInputValue(searchTerm);
  }, [searchTerm]);

  return (
    <div
      data-qa={dataQA}
      className={classNames(
        styles.container,
        styles[controlTheme],
        styles[controlSize],
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <Icon
        dataQA="search-icon"
        type="SearchIcon"
        className={styles.searchIcon}
      />
      <input
        ref={inputField}
        inputMode="none"
        type="text"
        placeholder={`Search ${projectTitle}`}
        value={inputValue}
        className={styles.input}
        onMouseDown={handleEventPropagation}
        onTouchEnd={handleEventPropagation}
        onChange={handleEventPropagation}
        onBlur={handleFocus}
        spellCheck={false}
      />
      <TextKeyboard
        handleClick={handleClick}
        handleBackspace={handleBackspace}
        handleArrowLeft={() => {
          handleArrowClick("left");
          onChange(inputValue);
        }}
        handleArrowRight={() => {
          handleArrowClick("right");
          onChange(inputValue);
        }}
      />

      <button
        data-qa="search-close"
        className={styles.closeIcon}
        onClick={onClose}
      >
        <Icon dataQA="search-close-icon" type="CloseIcon" />
      </button>
    </div>
  );
};

export default Keyboard;
